@import '../../style/variables.scss';
.not-found{
    text-align: center;
    margin-top: 60px;
    font-weight: 700;
    div{
        width: 132px;
    height: 132px;
    margin: auto;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 45px;
    -webkit-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
    box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
    }
    h1{
        
    }
    p{
        font-size: 40px;
        margin-top: 20px;
        color: $brand-primary;
    }
}