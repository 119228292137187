.background {
  z-index: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  background: white;
  width: 100%;
  height: 100%;
  font-family: 'poppins', sans-serif;
}
.loader-holder{
    background: #0033991c;
    display: flex;
    width: 90%;
    height: 100%;
    padding: 0 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loader {
        // border: 16px solid #f3f3f3;
        // border-radius: 50%;
        // border-top: 16px solid #3498db;
        width: 60%;
        // height: 120px;
        // -webkit-animation: spin 2s linear infinite; /* Safari */
        // animation: spin 2s linear infinite;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        text-align: center;
        margin: 40px 0;
      }
}
      // /* Safari */
      // @-webkit-keyframes spin {
      //   0% { -webkit-transform: rotate(0deg); }
      //   100% { -webkit-transform: rotate(360deg); }
      // }
      
      // @keyframes spin {
      //   0% { transform: rotate(0deg); }
      //   100% { transform: rotate(360deg); }
      // }

      @media screen and (min-width: 992px) {
        .loader-holder {
          .loader {
            width: 300px;
          }
        }
        
      }