.reset{
    .error-text{
        margin: 0px;
        margin-top: 3px;
        color: #ff3535;
        font-size: 13px;
        position: absolute;
    }
    
    .successMesage {
        text-align: center;
        color: #9c7b2e;
        font-weight: bold;
    }
}
