@import '../../style/variables.scss';

.register{
    background: #0033991c;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    .custom-input{
        fieldset{
            border-color: #003399 !important;
        }
        label{
            color:rgb(99, 99, 99);
        }
       
    &.mb15{
        margin-bottom: 15px;
    }
    }
    .top-content{
        z-index: 0;
        padding-bottom: 80px;
        padding-top: 60px;
        position: relative;
        //height: 150px;
        width: 100%;
        background: rgb(45,78,90);
        background: linear-gradient(0deg, #003399 0%, #3a79fa 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: 100%;
        background-position: center;
        .top-wrapper{

            .logo-holder{
                width: 160px;
                 height: 90px;
                border-radius: 10px;
                // background: white;
                margin: auto;
                margin-bottom: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                // background: #131c29;
                // -webkit-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
                // -moz-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
                // box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
                
                img{
                    width: 100%;
                    position: relative;
                }
                }
            }
            h1{
                font-size: 22px;
                font-weight: 700;
                color: white;
                margin: 0;
            }
        }
    .card-holder{
        position: relative;
        z-index: 1;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -40px;
        padding-bottom: 40px;
        h1 {
            font-size: 20px;
            text-align: center;
            color: #003399;
        }
        .form-card{
            max-width: 740px;
            margin: auto;
            .steps{
                margin: auto;
                width: 130px;
                display: flex;
                margin-bottom: 20px;
                align-items: center;
                .step{
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    border: solid 2px #003399;
                    color: #003399;
                    box-sizing: border-box;
                    font-weight: 700;
                    p{
                        margin: 0px;
                        position: relative;
                        top: 2px;
                    }
                    &.active{
                        background: #003399;
                        color: white;
                    }
                }
                .step-line{
                    width: 70px;
                    height: 1px;
                    background: #003399;
                }
            }
            padding: 20px;
            form{
                &>div{
                    display: block;
                    margin-bottom: 15px;
                }
                .profile-img-holder{
                    width: 150px;
                    overflow: hidden;
                    height: 150px;
                    margin-bottom: 10px;
                    position: relative;
                    .delete-sign{
                        position: absolute;
                        color: #01339C;
                        background: white;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        top: 7px;
                        right: 7px;
                    }
                    img{
                        width: 100%;
                    }
                }
            }
            .info-text{
                font-size: 13px;
                margin-top: 0px;
                margin-bottom: 30px;
                &.small-margin{
                    margin-bottom: 15px;
                }
            }
            .info-holder{
                text-align: center;
                p{
                    &:first-child{
                        margin-bottom: 0px;
                    }
                    &:last-child{
                        margin-top: 8px;
                        color: #003399;
                        font-weight: 600;
                        margin-bottom: 25px;
                    }
                }
            }
            .custom-btn-outlined{
                display: block;
                margin: auto;
                border: solid 2px;
                color: #013399;
                margin-bottom: 15px;
            }
            .custom-btn{
                background: #003399;
                margin-top: 20px;
                margin-bottom: 15px;
                font-weight: 700;
                font-size: 16px;
                min-height: 40px;
                &.mrr-10{
                    margin-right: 10px;
                }
                &.secondary{
                    margin-top: 0px;
                }
            }
            .svg_icons {
                transform: scale(1.2);
              }
              input::file-selector-button{
                  color:#003399;
                  background: transparent;
                  border:solid 2px;
                  padding: 10px;
                  text-transform: uppercase;
                  font-size: 14px;
                  font-weight: 700;
                  border-radius: 5px;
                  margin-right: 10px;
              }
              .file-input{
                  p{
                      margin-bottom: 8px;
                  }
              }
              .username-holder{
                text-align: center;
                font-weight: 600;
                color: #003399;
                font-size: 17px;
                span{
                    color: #656565;
                }
              }
              .address-holder{
                  display: flex;
                  >div{
                      width: 100%;
                      p{
                          &:last-child{
                            padding: 13px 10px;
                            border: solid 1px #003399;
                            width: 100%;
                            box-sizing: border-box;
                            border-radius: 4px;
                            span{
                                float: right;
                                position: relative;
                                top: 0px;
                                color: #003399;
                                cursor: pointer;
                            }
                          }
                      }
                  }
              }
           
        } 
        .css-l4t2zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: #013399;
        }
        .css-p8pboz-MuiFormLabel-root-MuiInputLabel-root.Mui-error{
            color: rgba(0, 0, 0, 0.6);
        }

    }
    .password-container{ 
        position: relative;
        .icon {
            position: absolute;
            height:  25px;
            top: calc(50% - 20px);
            right: 5px;
            svg {
                color: #003399;
            }
        }
    }
    .password-form-holder{
        margin-bottom: 25px;
    }
    .err-msg{
        font-size: 15px;
        color: red;
        &.small-text{
            margin-top: -5px;
            position: absolute;
        }
    }
    .popup-holder{
        padding: 25px;
        background: white;
        text-align: center;
        .mrr-10{
            margin-right: 10px;
        }
        .status-msg{
            color:red;
            text-align: center;
        }
    }
}

.register {
    .custom-input {
        &.required {
            fieldset {
                border-color:red!important;
            }
        }
    }
} 
.register {
    .custom-date {
        fieldset {
            border-color: #003399!important;
        }
    }
}

.button-link {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid white;
    background-color: white;
    color: #003399!important;
    margin-top: 40px;
    cursor: pointer;
    box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17);
    text-align: center;
    justify-content: center;
    margin: 50px 0;
    &:hover {
        background-color: rgba(255, 255, 255, 0.877);
    }
    a {
        font-weight: bold;
    }
}