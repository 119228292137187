.popup{
    z-index: 2;
    width: 100%;
    height: 100%;
    position: fixed;
    top:0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.627);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-box{
        width: 90%;
        position: relative;
        .close-btn{
            position: absolute;
            top: 15px;
            right: 15px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #003399;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            svg{
                font-size: 27px;
            }

        }
        .popup-content{
            width:100%;
            margin: auto;
            img{
                width: 100%;
            }
        }
    }
}