.cover-image {
    position: fixed;
    top: 0;
    height: 30vh;
    width: 100%;
    background: url(../../assets/images/coveralternate.jpg) center;
    background-size: cover;
    .top-btn{
        width: 40px;
        height: 40px;
        margin: 15px;
        background: #ffffffba;
        color: #5f5fd3;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .edit-icon {
        position: absolute;
        right: 5%;
        top: 5%;
        padding: 5px;
        // border: 1px solid white;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffffb5;
        color: #5f5fd3;
        /* border: solid 1px; */
        cursor: pointer;
    }
}

.detail {
    font-family: 'Poppins', sans-serif;
    border-radius: 30px;
    position: relative;
    top: 25vh;
    min-height: calc(75vh - 100px);
    background: white;
    //box-shadow: 0px -20px 20px 15px white;
    padding: 0px 5% 41%;
    .MuiSvgIcon-root {
        color: #003399!important;
    }
    .profile-image {
        position: absolute;
        left: calc(50% - 79px);
        top: calc(0px - 90px);
        width: 150px;
        height: 150px;
        background: url(../../assets/images/avatar.jpeg) center / cover;
        border-radius: 50%;
        border: 2px solid white;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
        
    }
    .user-id-holder{
        display: flex;
        justify-content: end;
        &.have-img{
            padding-top: 65px;
        }
        p{
            text-align: right;
            color: #ffffff;
            font-size: 16px;
            font-weight: 700;
            background: #5a77bc;
            display: inline-block;
            padding: 4px 20px 2px 20px;
            border-radius: 26px;
        }
    }
    h1 {
        margin: 10px 0 20px;
        text-align:center;
        overflow-wrap: break-word;
        &.top-space{
            padding-top: 74px;
        }
    }
    p
    h1 {
        text-align: center;
    }
    label{
        display: block;
        color: #5476bb;
        margin-bottom: 10px;
        //font-style: italic;
        text-align: center;
        font-weight: 600;
        padding-top: 18px;
        font-size: 16px;
    }
    .phone-holder{
        -webkit-box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17); 
        box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17);
        border-radius: 10px;
        margin-bottom: 20px;
        background: white;
        div{
            a{
                >div{
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    >div{
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background: #003399ab;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 10px;
                        
                        .MuiSvgIcon-root{
                            color: white!important;
                            font-size: 17px;
                        }
                    }
                    span{
                        font-size: 20px;
                        font-weight: 700;
                        max-height: 30px;
                        margin-right: 10px;
                    }
                    &.phone-two{
                        border-top: solid 1px #e5e5e5;
                    }
                }
            }
        }
    }
    .phone-number-holder {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 15px;
        
        a {
            width: 100%;
        }
        .phone-number {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #003399;
            background: #0033991c;
            padding: 10px 25px;
            border-radius: 20px;
            text-align: right;
            font-weight: bold;
            cursor: pointer;
            font-size: 1.3rem;
        }
        span {
            margin-right: 12px;
            font-size: 12px;
        }
    }
    .phoneInfo{
        font-size: 12px;
        margin-top: -12px;
        text-align: right;
        color: #5a77bc;
    }

    .note-holder {
        -webkit-box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17); 
        box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17);
        border-radius: 10px;
        margin-bottom: 20px;
        background: white;
        padding-bottom: 20px;
        .note {
            padding: 15px 20px 20px;
            /* border-radius: 12px; */
            /* border: 1px solid #003399; */
            text-align: center;
            color: #616161;
            font-weight: 500;
            

        }
    }
    .address-holder {
        margin-bottom: 10px;
        -webkit-box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17); 
        box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17);
        border-radius: 10px;
        margin-bottom: 20px;
        background: white;
        padding-bottom: 8px;
        label {
            margin-bottom: 5px;
        }
        p {
            font-weight: 600;
            text-align: center;
            color: #616161;
        }
        .map {
            height: 20vh;
            width: 100%;   
           
            .map-container {
                height: 100%;
            }
        }
    }
    .datetranspotr-holder{
        display: flex;
        justify-content: space-between;
        >div{
            width: 47%;
            &:only-child {
                width: 100%;
            }
        }
    }
    .info-holder {
        margin-bottom: 15px;
        -webkit-box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17); 
        box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17);
        border-radius: 10px;
        margin-bottom: 20px;
        background: white;
        padding-bottom: 20px;
        &.address {
            label {
                margin-bottom: 5px;
            }
            .info {
                padding: 5px 0!important;
            }
        }

        .info {
            border: 1px solid #003399;
            background: #0033991c;
            padding: 10px;
            border-radius: 20px;
            text-align: right;
            cursor: pointer;
            font-weight: bold;
            &.daterange-holder{
                line-height: 18px;
                margin-top: 20px;
            }
            span {
                
            }
            .icon-holder{
                width: 30px;
                height: 30px;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #5a77bc;
                border-radius: 50%;
                color: white;
                padding: 5px;
                margin-bottom: 5px;
                .MuiSvgIcon-root {
                    color: white !important;
                }
            }
        }
    }
    .divider {
        position: relative;
        border: 1px dotted #003399;
        margin: 40px 0;
        .divider-icon {
            position: absolute;
            top: -20px;
            left: calc(50% - 20px);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background: white;
            border: 1px solid #003399;
            border-radius: 50%;

        }
    }
    .additional-info {
        position: relative;
        z-index: 2;
        margin-bottom: 20%;
        h2 {
            text-align: center;
        }
        .info-holder {
            .info {
                background: none;
                border: none;
                padding: 10px 0;
                font-weight: 600;
                text-align: center;
                color: #616161;
                font-size: 16px;
            }
        }
    }
    .footer-image {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        &.logo{
            background: #71b8b1;
            width: 100px;
            margin-bottom: 16%;
            margin-left: 20px;
            padding: 10px;
            border-radius: 9px;
        }
    }
}

.blocked-tag-wrapper {
    height: 100vh;
    padding: 0 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    background-color: #003399;
    .MuiSvgIcon-root {
        font-size: 6rem;
        color: #fff!important;
    }
    h2 {
        margin-top: 3rem;
        margin-bottom: 0;
    }
    p {
        text-align: center;
    }
    img {
        margin-top: 20vh;
        max-width: 30%;
    }
}
.landing-holder {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    background: #003399;
    img {
        width: 30%;
    }
    h2 {
        color: #fff;
        margin: 50px 0 20px 0;
        // font-family: 'Great Vibes', cursive;
        font-size: 2rem;
    }
    .contact-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        padding: 40px;

        .contact-line {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            a {
                margin-left: 15px;
            }
        }
    }
    .login-button {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        border: 1px solid white;
        background-color: white;
        color: #003399!important;
        margin-top: 40px;
        cursor: pointer;
        box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.17);
        &:hover {
            background-color: rgba(255, 255, 255, 0.877);
        }
        span {
         font-weight: 600;
        margin-right: 10px;

        }
        .MuiSvgIcon-root {
        font-size: 2rem;
        
        }
    }
}

@media screen and (min-width: 380px) {
    .detail{
        label{
            font-size: 19px;
        }
        .additional-info{
            .info-holder{
                .info{
                    font-size: 18px;
                }
            }
        }
    } 
  }